import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../Assets/Images/Logo.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';


function Sidebar() {


   let history = useNavigate();

//   let LoginParse = JSON.parse(localStorage.getItem("valid"));
//    console.log("LoginParse" , LoginParse && LoginParse);

   const [toggle, setToggle] = useState(false);
   const [Bedsheet, setBedsheet] = useState(false);

   function handleLogout() {
      confirmAlert({
         // title: 'Confirm to submit',
         message: 'Are you sure you want to sign out?',
         buttons: [
            {
               label: 'Cancel',
               onClick: () => { }
            },
            {
               label: 'Ok',
               onClick: () => {

                  // history("/Login")
                  window.location.href = window.location.origin + "/Login";

               }

            }
         ]


      });
   }
   return (
      <>
         {toggle == false ?


            <div className="iq-sidebar">
               <div className="iq-sidebar-logo d-flex justify-content-between">
                  <a href="/HomeList">
                     <img src={logo} className="img-fluid" alt="" />
                     <span style={{ marginLeft: '5px', fontSize: '17px', lineHeight: '25px' }}>The Sustainable Fabrics</span>
                  </a>
                  <div className="iq-menu-bt align-self-center" onClick={() => setToggle(!toggle)}>
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
               </div>


               <div id="sidebar-scrollbar">
                  <nav className="iq-sidebar-menu">
                     <ul id="iq-sidebar-toggle" className="iq-menu">
                        <li className="iq-menu-title"><i className="ri-separator"></i><span>Main</span></li>

                        <li className={window.location.href.includes('/HomeList') || window.location.href.includes('/HomeAdd') || window.location.href.includes('/HomeEdit') ? "active point" : 'point'}>
                           <a className="iq-waves-effect collapsed" onClick={() => history('/HomeList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Home</span></a>

                        </li>

                        <li className={window.location.href.includes('/PagesList') || window.location.href.includes('/PagesAdd') || window.location.href.includes('/PagesEdit') ? "active point" : 'point'}>
                           <a className="iq-waves-effect collapsed" onClick={() => history('/PagesList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Pages</span></a>

                        </li>

                        <li className={window.location.href.includes('/ProductAdd') || window.location.href.includes('/ProductEdit') || window.location.href.includes('/ProductList') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/ProductList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Products</span></a>

                        </li>

                        <li className={window.location.href.includes('/OurTeamList') || window.location.href.includes('/OurTeamAdd') || window.location.href.includes('/OurTeamEdit') ? "active point" : 'point'}>
                           <a className="iq-waves-effect collapsed" onClick={() => history('/OurTeamList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Our Team</span></a>

                        </li>
                        <li className={window.location.href.includes('/FabricsCategoryAdd') || window.location.href.includes('/FabricsCategoryEdit') || window.location.href.includes('/FabricsCategoryList') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/FabricsCategoryList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Fabrics Category</span></a>

                        </li>

                        <li className={window.location.href.includes('/DealsAdd') || window.location.href.includes('/DealsEdit') || window.location.href.includes('/DealsList') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/DealsList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Deals</span></a>

                        </li>
                        <li className={window.location.href.includes('/PriceRangeAdd') || window.location.href.includes('/PriceRangeEdit') || window.location.href.includes('/PriceRangeList') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/PriceRangeList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Price Range</span></a>

                        </li>

                        <li className={window.location.href.includes('/ContactList') || window.location.href.includes('/ContactAdd') || window.location.href.includes('/ContactEdit') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/ContactList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Contact</span></a>

                        </li>
                        <li className={window.location.href.includes('/Inquiry') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/Inquiry')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Inquiry</span></a>

                        </li>

                           {/* Next relese */}
                        {/* <li className={window.location.href.includes('/TestimonialAdd') || window.location.href.includes('/TestimonialEdit') || window.location.href.includes('/TestimonialList') ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/TestimonialList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Testimonial</span></a>

                        </li>



                        <li className={window.location.href.includes('/AdminUserManagementList') || window.location.href.includes('/AdminUserManagementAdd')  ? "active point" : 'point'}>

                           <a className="iq-waves-effect collapsed" onClick={() => history('/AdminUserManagementList')} data-toggle="collapse" aria-expanded="true"><i className="ri-user-line"></i><span>Admin User Management</span></a>

                        </li> */}



                     </ul>
                  </nav>
                  <div className="p-3"></div>
               </div>
            </div> : ""}


         <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
               <div className="iq-sidebar-logo d-flex justify-content-between">
                  <div className="iq-menu-bt align-self-center" onClick={() => setToggle(!toggle)}>
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
               </div>
               {/* <div className="navbar-breadcrumb">
                  <h5 className="mb-0">Profile Edit</h5>
                  <nav aria-label="breadcrumb">
                     <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Profile Edit</li>
                     </ul>
                  </nav>
               </div> */}
               <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <i className="ri-menu-3-line"></i>
                  </button>
                  <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                     <ul className="navbar-nav ml-auto navbar-list">
                        <li className="nav-item iq-full-screen"><a href="#" className="iq-waves-effect" id="btnFullscreen"><i className="ri-fullscreen-line"></i></a></li>
                     </ul>
                  </div>
                  <ul className="navbar-list">
                     <li>
                        <a href="#" className="search-toggle iq-waves-effect bg-primary text-white"><img src="images/user/1.jpg" className="img-fluid" alt="user" /></a>
                        <div className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card shadow-none m-0">
                              <div className="iq-card-body p-0 ">
                                 <div className="bg-primary p-3">
                                    <h5 className="mb-0 text-white line-height">Hello  {localStorage.getItem('nameAdmin')}</h5>
                                    <span className="text-white font-size-12">Available</span>
                                 </div>

                                 <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn point" onClick={() => handleLogout()} role="button">Sign out<i className="ri-login-box-line ml-2"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>



      </>
   )
}

export default Sidebar