import React, { useEffect, useState } from "react";

// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
// import {
//   dispatchLoadingStatus,
//   pagesAdd_API,
//   pagesEdit_API,
// } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import { dispatchLoadingStatus, homepagesAdd_API, homepagesEdit_API, pagesEdit_API } from "../../API/Action/globalAction";

function PagesAdd(props) {
  const [propsValue, setPropsValue] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let htmlin = `<p className="text-black " >Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your sleep experience. Experience the ultimate luxury and comfort with our premium linen bedsheet. </p>
  `;

  const [fname, setFname] = useState("");
  const [content, setContent] = useState(htmlin);

  const [link, setLink] = useState();

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");


  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/HomeList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setFname(props.data.page_title);
      setSelectedFile(props.data.image);
      setContent(props.data.description);
      setLink(props.data.amazon_link);
    }
  }, [props]);
  let token = localStorage.getItem('token')

  // useEffect(() => {
   
  //     if (token) {
  //       window.location.href = window.location.origin + '/HomeAdd'
  //     } else {
  //       window.location.href = window.location.origin + '/Login'
  //     }
   
  // },[])




  const handleChange = (evt) => {
    const newContent = evt.editor.getData();

    setContent(newContent);

    if (newContent) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s]*$/;

  function handleFname(e) {
    let data = e.target.value.trim() == "" ? "" : e.target.value;

    
    if (nameRe.test(data)) {
      setFname(data);
  }

   

    if (e.target.value) {
      if (e.target.value.length >= 2) {
        setNameError(false);
        setNameError2("");
        if (!nameRe.test(e.target.value)) {
          setNameError(true);
          setNameError2("Please enter valid title");
        } else {
          setNameError(false);
          setNameError2("");
        }
      } else {
        setNameError(true);
        setNameError2("Please enter valid title");
      }
    } else {
      setNameError(true);
      setNameError2("Please enter title");
    }
  }

  function handleLink(e) {
    let data = e.target.value;
    setLink(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      setLinkError(false);
      setLinkError2("");
    }
    else {
      setLinkError(true);
      setLinkError2("Please enter link");

    }
  }


  function handleSubmit() {
    if (fname) {
      if (fname.length >= 2) {
        setNameError2("");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid title name");
        } else {
          setNameError2("");
        }
      } else {
        setNameError2("Please enter title name");
      }
    } else {
      setNameError2("Please enter title");
    }
    if (content) {
      setContentError2("");
    } else {
      setContentError2("Please enter description");
    }

    if (selectedFile) {
      setSelectedFileError2("");
    } else {
      setSelectedFileError2("Please select image");
    }

    if (link) {
      setLinkError2("");
    }
    else {
      setLinkError2("Please enter link");
    }


    if ( nameError == false && selectedFileError == false && contentError == false && linkError == false && fname && content && link && selectedFile) {
      let formData = new FormData();
      formData.append("page_image", selectedFile ? selectedFile : "");
      formData.append("description", content);
      formData.append("page_title", fname);
      formData.append("amazon_link", link);

      let data = propsValue ? propsValue.homepage_id ? propsValue.homepage_id : "" : "";

      if (data) {
        formData.append("homepage_id", data);


        dispatch(homepagesEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));
      } else {
        dispatch(homepagesAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
   
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.homepage_id
                                  ? "Home Edit"
                                  : "Home Add"
                                : "Home Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/HomeList">Home List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.homepage_id
                                  ? "Home Edit"
                                  : "Home Add"
                                : "Home Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}

                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>


                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">
                                  Upload Image:
                                </label>
                                <form>
                                  <div className="file">
                                    <div
                                      className="file__input"
                                      id="file__input"
                                    >
                                      <input
                                        className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label
                                        className="file__input--label"
                                        accept=".png, .jpg .jpeg"
                                        htmlFor="customFile"
                                        data-text-btn="Choose Files"
                                      >
                                        Add image:
                                      </label>
                                    </div>
                                  </div>
                                </form>
                                <p className="errorr">{selectedFileError2}</p>

                                {selectedFile && (
                                  <div
                                    className="choosed-files"
                                    style={{ position: "relative" }}
                                  >
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={
                                        typeof selectedFile === "string"
                                          ? selectedFile
                                          : URL.createObjectURL(selectedFile)
                                      }
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />

                                    <CloseSvg
                                      className="point"
                                      width={18}
                                      height={18}
                                      onClick={() => handleRemoveFile()}
                                      style={{
                                        zIndex: "99",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Link:</label>
                              <input type="text" className="form-control" value={link} onChange={(e) => handleLink(e)} />
                              <p className='errorr'>{linkError2}</p>
                            </div>

                            <div className="form-group col-sm-12">
                              <label>Long Description:</label>
                              <CKEditor
                                activeclassName="p10"
                                content={content}
                                events={{
                                  change: handleChange,
                                }}
                                config={{ allowedContent: true }}
                              />

                              <p className="errorr">{contentError2}</p>

                              {/* <textarea className="form-control" name="address" rows="5" value={longDes} onChange={(e) => {console.log("textarea:-" , e.target.value);  setLongDes(e.target.value) }} style={{lineHeight : '22px'}}/> */}
                            </div>
                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PagesAdd;
