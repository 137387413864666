import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { contactAdd_API, contactEdit_API, dispatchLoadingStatus, ourTeamAdd_API, ourTeamEdit_API, pagesAdd_API, pagesEdit_API } from '../../API/Action/globalAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import { ourTeamAdd_URL, ourTeamEdit_URL } from '../../OtherFiles/Constant';
import Select from 'react-select';




function Add(props) {

  const [propsValue, setPropsValue] = useState();


  const dispatch = useDispatch({

  });
  let history = useNavigate();




  const [title, setTitle] = useState('');
  const [department, setDepartment] = useState('');
  const [email, setEmail] = useState('');
  const [addressError2, setAddressError2] = useState();
  const [addressError, setAddressError] = useState(false);

  const [phone, setPhone] = useState('');


  const [content, setContent] = useState("");
  const [address, setAddress] = useState("");


  const [titleError, setTitleError] = useState(false);
  const [titleError2, setTitleError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailError2, setEmailError2] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [phoneError2, setPhoneError2] = useState("");


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/ContactList";

        } else {
          console.log('Cancel press')


        }
      });

  }


  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setTitle(props.data.title);
      setAddress(props.data.address_1);
      setContent(props.data.short_description);
      setEmail(props.data.email_id);
      setPhone(props.data.phone_1);

    }
  }, [props])
  const handleChange = (e) => {

    setContent(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      setContentError(false);
      setContentError2("");

    }
    else {
      setContentError(true);
      setContentError2("Please enter description");


    }
  };

  let nameRe = /^[a-zA-Z\s]*$/;
  let MobileRe = /^[0-9\b]+$/;

  function handlePhone(e) {
    let data = e.target.value.trim() == "" ? "" : e.target.value;
    setPhone(data ? data : "")
    if (data) {
      setPhoneError(false);
      setPhoneError2("")
    }
    else {
      setPhoneError(true);
      setPhoneError2("Please enter phone");

    }


  }
  function handleAdd(e){
    setAddress(e.target.value.trim() == "" ? "" : e.target.value);

    if(e.target.value){
      setAddressError(false);
      setAddressError2("");


    }
    else{
      setAddressError(true);
      setAddressError2("Please enter address");
    }

  }

  function handleEmail(e) {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {

      if (!isValidEmail(e.target.value)) {
        setEmailError(true);
        setEmailError2("Email not valid")
      }
      else {
        setEmailError(false);
        setEmailError2("")

      }
    }
    else {
      setEmailError(false);
      setEmailError2("Email is required")
    }

  }


  function handleTitle(e) {
    setTitle(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {

      if (e.target.value.length >= 2) {
        setTitleError(false);
      setTitleError2("");

        if (!nameRe.test(e.target.value)) {
          setTitleError(true);
      setTitleError2("Please enter valid title");


        }
        else {
          setTitleError(false);
      setTitleError2("");

        }
      }
      else {
        setTitleError(true);
      setTitleError2("Please enter valid title");

      }
    }
    else {
      setTitleError(true);
      setTitleError2("Please enter title");


    }

  }


  function handleSubmit() {
    if(address){
      setAddressError2("");


    }
    else{
      setAddressError2("Please enter address");
    }

    if(content){
      setContentError2("");


    }
    else{
      setContentError2("Please enter address");
    }

    if (title) {

      if (title.length >= 2) {
      setTitleError2("");

        if (!nameRe.test(title)) {
      setTitleError2("Please enter valid title");


        }
        else {
          setTitleError(false);
      setTitleError2("");

        }
      }
      else {
      setTitleError2("Please enter valid title");

      }
    }
    else {
      setTitleError2("Please enter title");
    }

    if (email) {

      if (!isValidEmail(email)) {
        setEmailError2("Email not valid")
      }
      else {
        setEmailError2("")
      }
    }
    else {
      setEmailError2("Email is required")
    }

    if (phone) {
      setPhoneError2("")
    }
    else {
      setPhoneError2("Please enter phone");

    }


    if (titleError == false && emailError == false && phoneError == false && contentError == false && addressError == false && email && title && phone && content && address) {
      MainFun()



    }
  }

  function MainFun() {
    let formData = new FormData();
    formData.append('title', title);
    formData.append('short_description', content);
    formData.append('email_id', email);
    formData.append('phone_1', phone);
    formData.append('address_1', address);

    let data = propsValue ? propsValue.contact_id ? propsValue.contact_id : "" : ""


    if (data) {
      formData.append('contact_id', data);

      dispatch(dispatchLoadingStatus(true));

      dispatch(contactEdit_API(formData));

    }

    else {
      dispatch(dispatchLoadingStatus(true));

      dispatch(contactAdd_API(formData));
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />


        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" email="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.contact_id ? "Contact Edit" : "Contact Add" : "Contact Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/OurTeamList">Contact List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.contact_id ? "Contact Edit" : "Contact Add" : "Contact Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">


                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Title:</label>
                              <input type="text" className="form-control" value={title} onChange={(e) => handleTitle(e)} />
                              <p className='errorr'>{titleError2}</p>
                            </div>


                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Email:</label>
                              <input type="text" className="form-control" value={email} onChange={(e) => handleEmail(e)} />
                              <p className='errorr'>{emailError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Phone:</label>
                              <input type="text" className="form-control" value={phone} onChange={(e) => handlePhone(e)}  maxLength={20}/>
                              <p className='errorr'>{phoneError2}</p>
                            </div>

                            <div class="form-group col-sm-12">
                              <label>Address:</label>
                              <textarea class="form-control" rows="5" value={address} onChange={(e) => handleAdd(e)}
                                style={{ lineHeight: '22px' }}>

                              </textarea>
                              <p className='errorr'>{addressError2}</p>

                            </div>


                            <div class="form-group col-sm-12">
                              <label>Description:</label>
                              <textarea class="form-control" rows="5" value={content} onChange={(e) => handleChange(e)}
                                style={{ lineHeight: '22px' }}>

                              </textarea>
                              <p className='errorr'>{contentError2}</p>

                            </div>

                          </div>
                          <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                          <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add