import React, { useEffect, useState } from "react";

// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
// import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
import { ReactComponent as CloseSvg } from "../../../Assets/Images/icons8-no.svg";
// import {
//   dispatchLoadingStatus,
//   pagesAdd_API,
//   productAdd_API,
//   getAllCategoriesTitle_API,
//   getfabricCategory_API,
//   getDeals_API,
//   getPriceRange_API,
//   productEdit_API,
// } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import Sidebar from "../../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  fabricCategoryEdit_API,
  febricCategoryAdd_API,
  getAllCategoriesTitle_API,
  getDeals_API,
  getPriceRange_API,
  getfabricCategory_API,
  productAdd_API,
  productEdit_API,
} from "../../../API/Action/globalAction";

function FebricsCategoryAdd(props) {
  const [propsValue, setPropsValue] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  const [allCategory, setAllCategory] = useState();
  const [febricCategory, setFebricCategory] = useState();
  const [dealCategory, setDealCategory] = useState();
  const [rangeCategory, setRangeCategory] = useState();

  const [productName, setProductName] = useState();
  const [price, setPrice] = useState();
  const [link, setLink] = useState();
  const [sendFebric, setSendFebric] = useState();
  const [sendDeals, setSendDeals] = useState();
  const [sendRange, setSendRange] = useState();
  const [sendDes, setSendDes] = useState();

  const [fname, setFname] = useState("");
  const [content, setContent] = useState("");
  const [profuctError, setProductError] = useState(false);
  const [productError2, setProductError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [priceError, setPriceError] = useState(false);
  const [priceError2, setPriceError2] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [febricError, setFebricError] = useState(false);
  const [febricError2, setFebricError2] = useState("");

  const [dealError, setDealError] = useState(false);
  const [dealError2, setDealError2] = useState("");

  const [rangeError, setRangeError] = useState(false);
  const [rangeError2, setRangeError2] = useState("");

  const [desError, setDesError] = useState(false);
  const [desError2, setDesError2] = useState("");

  let getAllCategoriesTitleReducer = useSelector(
    (state) => state.globalReducer.getAllCategoriesTitleReducer
  );
  let getfabricCategoryReducer = useSelector(
    (state) => state.globalReducer.getfabricCategoryReducer
  );
  let getDealsReducer = useSelector(
    (state) => state.globalReducer.getDealsReducer
  );
  let getPriceRangeReducer = useSelector(
    (state) => state.globalReducer.getPriceRangeReducer
  );

  let febricCategoryListReducer = useSelector(
    (state) => state.globalReducer.febricCategoryListReducer
  );
  // let data = allCategory ? allCategory : [];

  // let data2 = febricCategory ? febricCategory : [];

  // const indexOfTypeOfFabric = data.findIndex(
  //   (item) => item.categoryTitle === "Type of Fabric"
  // );

  // if (indexOfTypeOfFabric !== -1) {
  //   data[indexOfTypeOfFabric].data2 = data2;
  // }

  // console.log(data);

  // useEffect(() => {
  //   if (getAllCategoriesTitleReducer) {
  //     if (getAllCategoriesTitleReducer.success == true) {
  //       setAllCategory(getAllCategoriesTitleReducer.data);
  //     } else {
  //       setAllCategory([]);
  //     }
  //   }
  // }, [getAllCategoriesTitleReducer]);

  useEffect(() => {
    if (getfabricCategoryReducer) {
      if (getfabricCategoryReducer.errorcode == 0) {
        setFebricCategory(getfabricCategoryReducer.data);
      } else {
        setFebricCategory([]);
      }
    }
  }, [getfabricCategoryReducer]);

  // useEffect(() => {
  //   if (getPriceRangeReducer) {
  //     if (getPriceRangeReducer.errorcode == 0) {
  //       setRangeCategory(getPriceRangeReducer.data);
  //     } else {
  //       setRangeCategory([]);
  //     }
  //   }
  // }, [getPriceRangeReducer]);

  // useEffect(() => {
  //   if (getDealsReducer) {
  //     if (getDealsReducer.errorcode == 0) {
  //       setDealCategory(getDealsReducer.data);
  //     } else {
  //       setDealCategory([]);
  //     }
  //   }
  // }, [getDealsReducer]);

  useEffect(() => {
    if (febricCategoryListReducer) {
      if (getDealsReducer.errorcode == 0) {
        setDealCategory(febricCategoryListReducer.data);
      } else {
        setDealCategory([]);
      }
    }
  }, [febricCategoryListReducer]);

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/FabricsCategoryList";
      }
    });
  }

  useEffect(() => {
    // dispatch(getAllCategoriesTitle_API());
    dispatch(getfabricCategory_API());
  }, []);

  useEffect(() => {
    if (props.data) {
      console.log("hii", props.data);
      setPropsValue(props.data);
      setProductName(props.data.type_of_category);
      // setFname(props.data.name);
      setSelectedFile(props.data.image);
      setSendDes(props.data.description);
      // setDepartment(props.data.department);
      // setRole(props.data.role)
    }
  }, [props]);

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s]*$/;

  function handleProductName(e) {
    let data = e.target.value.trim() == "" ? "" : e.target.value;

    if(nameRe.test(data) ){
      setProductName(data);

    }

    if (data) {
      if (data.length >= 2) {
        setProductError(false);
        // if (!nameRe.test(data)) {
        //   setProductError(true);
        // }
        // else {
        //   setProductError(false);
        // }
      } else {
        setProductError(true);
      }
    } else {
      setProductError(true);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  function handleDes(e) {
    let data = e.target.value;
    setSendDes(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      setDesError(false);
    } else {
      setDesError(true);
    }
  }

  function handleSubmit() {
    if (productName) {
      if (productName.length >= 2) {
        setProductError2("");
        // if (!nameRe.test(productName)) {
        //   setProductError2("Please enter valid product name");
        // }
        // else {
        //   setProductError2("");
        // }
      } else {
        setProductError2("Please enter category name");
      }
    } else {
      setProductError2("Please enter category");
    }

    if (!profuctError && productName) {
      let formData = new FormData();

      formData.append("type_of_category", productName);
      formData.append("category_image", selectedFile ? selectedFile : "");
      formData.append("description", sendDes);

      if (propsValue) {
        if (propsValue.fabricCategory_id) {
          let formData2 = new FormData();

          formData2.append("type_of_category", productName);
          formData2.append("category_image", selectedFile ? selectedFile : "");

          formData2.append(
            "fabricCategory_id",
            props ? props.data.fabricCategory_id : ""
          );
          formData2.append("description", sendDes);
          dispatch(fabricCategoryEdit_API(formData2));
          dispatch(dispatchLoadingStatus(true));
        } else {
          dispatch(febricCategoryAdd_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }
      } else {
        dispatch(febricCategoryAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.fabricCategory_id
                                  ? "Fabrics Category Edit"
                                  : "Fabrics Category Add"
                                : "Fabrics Category Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/FabricsCategoryList">Fabrics Category List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.fabricCategory_id
                                  ? "Fabrics Category Edit"
                                  : "Fabrics Category Add"
                                : "Fabrics Category Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className="row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={productName}
                                onChange={(e) => handleProductName(e)}
                              />
                              <p className="errorr">{productError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">
                                  Upload Image:
                                </label>
                                <form>
                                  <div className="file">
                                    <div
                                      className="file__input"
                                      id="file__input"
                                    >
                                      <input
                                        className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label
                                        className="file__input--label"
                                        accept=".png, .jpg .jpeg"
                                        htmlFor="customFile"
                                        data-text-btn="Choose Files"
                                      >
                                        Add image:
                                      </label>
                                    </div>
                                  </div>
                                </form>
                                <p className="errorr">{selectedFileError2}</p>

                                {selectedFile && (
                                  <div
                                    className="choosed-files"
                                    style={{ position: "relative" }}
                                  >
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={
                                        typeof selectedFile === "string"
                                          ? selectedFile
                                          : URL.createObjectURL(selectedFile)
                                      }
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />

                                    <CloseSvg
                                      className="point"
                                      width={18}
                                      height={18}
                                      onClick={() => handleRemoveFile()}
                                      style={{
                                        zIndex: "99",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group col-sm-12">
                              <label>Description:</label>

                              <textarea
                                className="form-control"
                                name="address"
                                rows="5"
                                value={sendDes}
                                onChange={(e) => handleDes(e)}
                                style={{ lineHeight: "22px" }}
                              />

                              <p className="errorr"> {desError2}</p>
                            </div>
                            {/* {data && data.length > 0 ? data.map((i , index) =>
                             <div key={index} className="form-group col-sm-6">
                             <label htmlFor="fname">{i.categoryTitle}</label>
                             <Select
                               options={i.data2 ? i.data2.map((dataItem) => ({
                                 value: dataItem.type_of_category,
                                 label: dataItem.type_of_category,
                               })) : []}
                               placeholder="Select"
                               isClearable={false}
                               onChange={(e) => console.log("selected " , e)}
                             />
                           </div>
                            ) : ""} */}

                            {/* <div className="form-group col-sm-6">
                              <label htmlFor="fname">Type of Fabric:</label>
                              <Select
                                options={
                                  febricCategory
                                    ? febricCategory.map((dataItem) => ({
                                        value: dataItem.fabricCategory_id,
                                        label: dataItem.type_of_category,
                                      }))
                                    : [{ label: "Select", value: "" }]
                                }
                                placeholder="Select"
                                isClearable={false}
                                value={sendFebric}
                                onChange={(e) => handleFebric(e)}
                              />
                              <p className="errorr">{febricError2}</p>
                            </div> */}
                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FebricsCategoryAdd;
