import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, pagesAdd_API, productAdd_API, getAllCategoriesTitle_API, getfabricCategory_API, getDeals_API, getPriceRange_API, productEdit_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import Select from 'react-select';



function Add(props) {
  const [remember, setRemember] = useState(false);

  const [propsValue, setPropsValue] = useState();


  const dispatch = useDispatch({

  });
  let history = useNavigate();
  const [allCategory, setAllCategory] = useState();
  const [febricCategory, setFebricCategory] = useState();
  const [dealCategory, setDealCategory] = useState();
  const [rangeCategory, setRangeCategory] = useState();

  const [productName, setProductName] = useState();
  const staticText = "$"; 
  const [price, setPrice] = useState("");
  const [link, setLink] = useState();
  const [sendFebric, setSendFebric] = useState();
  const [sendDeals, setSendDeals] = useState();
  const [sendRange, setSendRange] = useState();
  const [sendDes, setSendDes] = useState();

  console.log("sendDeals" , sendDeals ? sendDeals : "");


  const [fname, setFname] = useState('');
  const [content, setContent] = useState("");
  const [profuctError, setProductError] = useState(false);
  const [productError2, setProductError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [priceError, setPriceError] = useState(false);
  const [priceError2, setPriceError2] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [febricError, setFebricError] = useState(false);
  const [febricError2, setFebricError2] = useState("");

  const [dealError, setDealError] = useState(false);
  const [dealError2, setDealError2] = useState("");

  const [rangeError, setRangeError] = useState(false);
  const [rangeError2, setRangeError2] = useState("");

  const [desError, setDesError] = useState(false);
  const [desError2, setDesError2] = useState("");

  let getAllCategoriesTitleReducer = useSelector(state => state.globalReducer.getAllCategoriesTitleReducer);
  let getfabricCategoryReducer = useSelector(state => state.globalReducer.getfabricCategoryReducer);
  let getDealsReducer = useSelector(state => state.globalReducer.getDealsReducer);
  let getPriceRangeReducer = useSelector(state => state.globalReducer.getPriceRangeReducer);




  // let data = allCategory ? allCategory : [];

  // let data2 = febricCategory ? febricCategory : [];

  // const indexOfTypeOfFabric = data.findIndex(
  //   (item) => item.categoryTitle === "Type of Fabric"
  // );

  // if (indexOfTypeOfFabric !== -1) {
  //   data[indexOfTypeOfFabric].data2 = data2;
  // }

  // console.log(data);


  useEffect(() => {
    if (getAllCategoriesTitleReducer) {
      if (getAllCategoriesTitleReducer.success == true) {
        setAllCategory(getAllCategoriesTitleReducer.data);

      }
      else {
        setAllCategory([]);
      }

    }
  }, [getAllCategoriesTitleReducer])


  useEffect(() => {
    if (getfabricCategoryReducer) {
      if (getfabricCategoryReducer.errorcode == 0) {
        setFebricCategory(getfabricCategoryReducer.data);

      }
      else {
        setFebricCategory([]);

      }

    }

  }, [getfabricCategoryReducer])

  useEffect(() => {
    if (getPriceRangeReducer) {
      if (getPriceRangeReducer.errorcode == 0) {


        setRangeCategory(getPriceRangeReducer.data);

      }
      else {
        setRangeCategory([]);

      }

    }

  }, [getPriceRangeReducer])

  useEffect(() => {
    if (getDealsReducer) {
      if (getDealsReducer.errorcode == 0) {
        setDealCategory(getDealsReducer.data);

      }
      else {
        setDealCategory([]);

      }

    }

  }, [getDealsReducer])



  useEffect(() => {
    if (props) {
      if (props.data) {
        setPropsValue(props.data);
        setSelectedFile(props.data.image);
        setProductName(props.data.product_name)
        setPrice(props.data.price);
        setLink(props.data.amazon_link);
        setRemember(props.data.features == null ? false : true);

        setSendDes(props.data.description)

        let data = getfabricCategoryReducer ? getfabricCategoryReducer.data.filter((i) => i.fabricCategory_id == props.data.fabricCategory_id) : "";

        let data2 = getDealsReducer ? getDealsReducer.data.filter((i) => i.deals_id == props.data.deals_id) : "";
        let data3 = getPriceRangeReducer ? getPriceRangeReducer.data.filter((i) => i.pricerange_id == props.data.pricerange_id) : "";
        console.log("ddd", data);

        setSendFebric([{ label: data ? data[0].type_of_category : "", value: data ? data[0].fabricCategory_id : "" }]);

        setSendDeals([{ label: data2 ? data2[0].type_of_price : "", value: data2 ? data2[0].deals_id : "" }]);

      }
    }

  }, [props])


  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/ProductList";

        }
      });

  }


  useEffect(() => {
    dispatch(getAllCategoriesTitle_API());
    dispatch(getfabricCategory_API());
    dispatch(getDeals_API());
    dispatch(getPriceRange_API());
  }, [])




  const handleFileChange = (event) => {
    console.log("imgg", event.target.files[0]);
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

        setSelectedFile(file);
        setSelectedFileError(false);
        setSelectedFileError2("");

      }
      else {
        setSelectedFileError2("The image must be a file of type: jpg, png, jpeg");
        setSelectedFileError(true);


      }
    }
    else {
      setSelectedFileError(true);

    }


  };


  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s]*$/;
  // let phoneRe = /^[0-9\b]+$/;
  const phoneRe = /^[0-9$]+$/;
  const productNameRegex = /^[a-zA-Z0-9\s]+$/;

  function handleProductName(e) {
    let data = e.target.value;
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;
    setProductName(productNameRegex.test(data2) ? data2 : "");

    if (data) {

      if (data.length >= 2) {
        setProductError(false);
        setProductError2("");
        if (!productNameRegex.test(data)) {
          setProductError(true);
          setProductError2("Please enter valid product name");

        }
        else {
          setProductError(false);
          setProductError2("");

        }
      }
      else {
        setProductError(true);
        setProductError2("Please enter valid product name");

      }
    }
    else {
      setProductError(true);
      setProductError2("Please enter product name");
    }

  }

  function handleDeal(e) {
    setSendDeals(e);


    if (e) {
      setDealError(false);
    }
    else {
      setDealError(true);

    }
  }

  function handleDes(e) {
    let data = e.target.value;
    setSendDes(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      setDesError(false);
    }
    else {
      setDesError(true);

    }
  }

  function handleFebric(e) {
    setSendFebric(e);


    if (e) {
      setFebricError(false);
    }
    else {
      setFebricError(true);

    }
  }

  function handlePrice(e) {
    let data = e.target.value;
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;
    let data3 = phoneRe.test(data2) ? data2 : "";

    setPrice(data3.replace(/^\$/, ''));

    if (data) {


        if (!phoneRe.test(data)) {
          setPriceError(true);
          setPriceError2("Please enter valid price");


        }
        else {
          setPriceError(false);
          setPriceError2("");

      }
     
    }
    else {
      setPriceError(true);
      setPriceError2("Please enter price");

    }
  }
  function handleRang(e) {
    setSendRange(e);


    if (e) {
      setRangeError(false);
    }
    else {
      setRangeError(true);

    }
  }

  function handleLink(e) {
    let data = e.target.value;
    setLink(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      setLinkError(false);
    }
    else {
      setLinkError(true);

    }
  }
  function handleSubmit() {

    if (productName) {

      if (productName.length >= 2) {
        setProductError2("");
        if (!nameRe.test(productName)) {
          setProductError2("Please enter valid product name");

        }
        else {
          setProductError2("");

        }
      }
      else {
        setProductError2("Please enter product name");

      }
    }
    else {
      setProductError2("Please enter product");
    }

    if (price) {

      setPriceError2("");
    
      if (!phoneRe.test(price)) {
        setPriceError2("Please enter valid price");

      }
      else {
        setPriceError2("");

      }


    }
    else {
      setPriceError2("Please enter price");
    }

    if (selectedFile) {
      setSelectedFileError2("");

    }
    else {
      setSelectedFileError2("Please select image");


    }

    if (link) {
      setLinkError2("");
    }
    else {
      setLinkError2("Please enter link");
    }

    if (sendDeals) {
      setDealError2("");
    }
    else {
      setDealError2("Please select deals");

    }

    if (sendFebric) {
      setFebricError2("");
    }
    else {
      setFebricError2("Please select type of fabric");

    }

    if (sendDes) {
      setDesError2("");
    }
    else {
      setDesError2("Please enter description");

    }

    if (sendRange) {
      setRangeError2("");
    }
    else {
      setRangeError2("Please select range");

    }

    if (sendDeals) {
      setFebricError2("");
    }
    else {
      setFebricError2("Please select type of fabric");

    }



    if (!profuctError && !priceError && !linkError && !febricError && !dealError && !desError && !selectedFileError &&

      productName && price && sendDeals && sendDes && sendFebric && selectedFile && link) {
      let formData = new FormData();

      formData.append('product_images', selectedFile ? selectedFile : "");
      formData.append('description', sendDes);
      formData.append('product_name', productName);
      formData.append('price', staticText + price);
      formData.append('amazon_link', link);
      formData.append('deals_id', sendDeals ? sendDeals.value : "");
      formData.append('fabricCategory_id', sendFebric ? sendFebric.value : "");
      formData.append('features', remember ? remember == true ? 1 : "" : "");

      


      if (propsValue) {

        if (propsValue.fabricCategory_id) {

          let formData2 = new FormData();

          console.log("imagee", selectedFile ? selectedFile : "");
          formData2.append('product_images', selectedFile ? selectedFile : "");
          formData2.append('description', sendDes);
          formData2.append('product_name', productName);
          formData2.append('price', staticText + price);
          formData2.append('amazon_link', link);
          console.log("value" , sendDeals);
          formData2.append('deals_id',  sendDeals.value ? sendDeals.value : sendDeals[0].value );
          formData2.append('fabricCategory_id',   sendFebric.value ? sendFebric.value  : sendFebric[0].value);
          formData2.append('product_id', propsValue ? propsValue.product_id : "");
          formData2.append('features', remember ? remember == true ? 1 : "" : "");
          dispatch(productEdit_API(formData2));
          dispatch(dispatchLoadingStatus(true));
        }
        else {
          dispatch(productAdd_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }
      }
      else {
        dispatch(productAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }




  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />


        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/ProductList">Product List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className="row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Product Name:</label>
                              <input type="text" className="form-control" value={productName} onChange={(e) => handleProductName(e)} minLength={3} maxLength={50} />
                              <p className='errorr'>{productError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Price:</label>
                              <input type="text" className="form-control" value={staticText + price} onChange={(e) => handlePrice(e)} />
                              <p className='errorr'>{priceError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Link:</label>
                              <input type="text" className="form-control" value={link} onChange={(e) => handleLink(e)} />
                              <p className='errorr'>{linkError2}</p>
                            </div>
                            {/* {data && data.length > 0 ? data.map((i , index) =>
                             <div key={index} className="form-group col-sm-6">
                             <label htmlFor="fname">{i.categoryTitle}</label>
                             <Select
                               options={i.data2 ? i.data2.map((dataItem) => ({
                                 value: dataItem.type_of_category,
                                 label: dataItem.type_of_category,
                               })) : []}
                               placeholder="Select"
                               isClearable={false}
                               onChange={(e) => console.log("selected " , e)}
                             />
                           </div>
                            ) : ""} */}

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Type of Fabric:</label>
                              <Select
                                options={febricCategory ? febricCategory.map((dataItem) => ({
                                  value: dataItem.fabricCategory_id,
                                  label: dataItem.type_of_category,
                                })) : [{ label: "Select", value: "" }]}
                                placeholder="Select"
                                isClearable={false}
                                value={sendFebric}
                                onChange={(e) => handleFebric(e)}
                              />
                              <p className='errorr'>{febricError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Deals:</label>
                              <Select
                                options={dealCategory ? dealCategory.map((dataItem) => ({
                                  value: dataItem.deals_id,
                                  label: dataItem.type_of_price,
                                })) : [{ value: "", label: "Select" }]}
                                placeholder="Select"
                                isClearable={false}
                                value={sendDeals}
                                onChange={(e) => handleDeal(e)}
                              />
                              <p className='errorr'>{dealError2}</p>

                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image:</label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">Add image:</label>
                                    </div>
                                  </div>

                                </form>
                                <p className='errorr'>{selectedFileError2}</p>


                                {selectedFile && (
                                  <div className="choosed-files" style={{ position: 'relative' }}>
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile : URL.createObjectURL(selectedFile)}
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()} style={{ zIndex: '99', position: 'absolute', right: '8px', top: '8px' }} />

                                  </div>
                                )}

                              </div>

                            </div>


                            <div className="form-group col-sm-12">
                              <label>Description:</label>

                              <textarea className="form-control" name="address" rows="5" value={sendDes} onChange={(e) => handleDes(e)} style={{ lineHeight: '22px' }} />

                              <p className='errorr'> {desError2}</p>

                            </div>
                              
                           {/* Next relese */}
                            {/* <div className="form-group col-sm-12">
                            <label ></label>

                              {
                                remember ?
                                  <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checked.svg" style={{ height: 15, width: 15, marginLeft:'0' }} /> :
                                  <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checkbox-empty.svg" style={{ height: 15, width: 15, marginLeft:'0' }} />
                              }
                              <label onClick={() => setRemember(!remember)} style={{marginLeft:'20px'}}>Product as featured ?</label>
                            </div> */}



                          </div>
                          <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                          <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add