import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, ourTeamAdd_API, ourTeamEdit_API, ourTeamSequence_API, pagesAdd_API, pagesEdit_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import { ourTeamAdd_URL, ourTeamEdit_URL } from '../../OtherFiles/Constant';
import Select from 'react-select';


function Add(props) {

  const [propsValue, setPropsValue] = useState();

  let dataList = [{ value: "Leadership", label: "Leadership" }, { value: "Development", label: "Development" }, { value: "Design", label: "Design" }
    , { value: "Marketing", label: "Marketing" }, { value: "Front End", label: "Front End" }, { value: "Back End", label: "Back End" }
    , { value: "Quality Assurance (QA)", label: "Quality Assurance (QA)" }, { value: "Others", label: "Others" }]

  const dispatch = useDispatch({

  });
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let ourTeamSequenceReducer = useSelector(state => state.globalReducer.ourTeamSequenceReducer);


  let htmlin = `<h1 className="fw-bold text-black">Our Story</h1>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>`;


  const [fname, setFname] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [sendDepartment, setSendDepartment] = useState();




  const [content, setContent] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");

  const [departmentError, setDepartmentError] = useState(false);
  const [departmentError2, setDepartmentError2] = useState("");

  const [sequnceList, setSequnceList] = useState();



  console.log("sequnce list", sequnceList && sequnceList);

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/OurTeamList";

        } else {
          console.log('Cancel press')


        }
      });

  }


  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setFname(props.data.name);
      setSelectedFile(props.data.image);
      setContent(props.data.short_description);

      let data2 = dataList && dataList.filter((i) => i.value == props.data.department);
      console.log("fff", data2);
      setSendDepartment(data2 && data2.length > 0 ? data2[0] : [{ value: "Others", label: "Others" }]);
      setDepartment(data2 && data2.length > 0 ? "" : props.data.department)
      setRole(props.data.role);
      setSequnce(props.data.sequence_No);
    }
  }, [props])
  const handleChange = (evt) => {
    setContent(evt);

    if (evt) {
      setContentError(false);
      setContentError2("");

    }
    else {
      setContentError(true);
      setContentError2("Please enter description");


    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

        setSelectedFile(file);
        setSelectedFileError2("");
      }
      else {
        setSelectedFileError2("The image must be a file of type: jpg, png, jpeg");

      }
    }
    else {
      setSelectedFileError2("");
    }


  };


  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s-]*$/;
  let nameRe2 = /^[a-zA-Z\-]*$/;
  let phoneRe = /^[1-9\b]/;

  const [sequnce, setSequnce] = useState("");
  const [sequnceError2, setSequnceError2] = useState("");
  const [sequnceError, setSequnceError] = useState("");



  function handleSequnce(e) {
    let data = e.target.value;
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;

    setSequnce(phoneRe.test(data2) ? data2 : "");

    if (data2) {
      setSequnceError(false);
      setSequnceError2("");

      if (!phoneRe.test(data)) {
        setSequnceError(true);
        setSequnceError2("Please enter valid sequence ");


      }
      else {
        setSequnceError(false);
        setSequnceError2("");


      }

    }
    else {
      setSequnceError(true);
      setSequnceError2("Please select sequence");

    }
  }
  function handleRole(e) {

    let data2 = e.target.value.trim() == "" ? "" : e.target.value;

    if (nameRe.test(data2)) {
      setRole(data2);
      setRoleError2("");

    }


    if (data2) {

      if (data2.length >= 2) {
        setRoleError(false);
        setRoleError2("");
        if (!nameRe.test(data2)) {
          setRoleError(true);
          setRoleError2("Please enter valid role");

        }
        else {
          setRoleError(false);
          setRoleError2("");

        }
      }
      else {
        setRoleError(true);
        setRoleError2("Please enter valid role");

      }
    }
    else {
      setRoleError(true);
      setRoleError2("Please enter role");


    }
  }




  function handleDepartment2(e) {

    setSendDepartment(e);
    setDepartment("")

    if (e) {

      setDepartmentError(false);
      setDepartmentError2("");

    }
    else {
      setSequnceList([])
      setDepartmentError(true);
      setDepartmentError2("Please select department");



    }
  }
  function handleDepartment(e) {

    let data = e.trim() == "" ? "" : e;
    setDepartment(nameRe.test(data) ? data : "");

    if (data) {

      if (data.length >= 2) {
        setDepartmentError(false);
        setDepartmentError2("");
        if (!nameRe.test(data)) {
          setDepartmentError(true);
          setDepartmentError2("Please enter valid department");

        }
        else {
          setDepartmentError(false);
          setDepartmentError2("");

        }
      }
      else {
        setDepartmentError(true);
        setDepartmentError2("Please enter valid department");

      }
    }
    else {
      setDepartmentError(true);
      setDepartmentError("Please enter department");


    }
  }

  function handleFname(e) {
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;

    if (nameRe.test(data2)) {
      setFname(data2);

    }

    if (data2) {

      if (data2.length >= 2) {
        setNameError(false);
        if (!nameRe.test(data2)) {
          setNameError(true);

        }
        else {
          setNameError(false);
        }
      }
      else {
        setNameError(true);
      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter title");


    }

  }


  function handleSubmit() {
    if (sequnce) {

      setSequnceError(false);
      setSequnceError2("");
      if (!phoneRe.test(sequnce)) {
        setSequnceError(true);
        setSequnceError2("Please enter valid sequence ");


      }
      else {
        setSequnceError(false);
        setSequnceError2("");


      }

    }
    else {
      setSequnceError(true);
      setSequnceError2("Please select sequence");

    }

    if (fname) {

      if (fname.length >= 2) {
        setNameError2("");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid name");

        }
        else {
          setNameError2("");

        }
      }
      else {
        setNameError2("Please enter name");

      }
    }
    else {
      setNameError2("Please enter name ");


    }


    if (selectedFile) {
      setSelectedFileError2("");

    }
    else {
      setSelectedFileError2("Please select image");


    }

    if (sendDepartment && sendDepartment.value == "Others") {
      if (department) {

        if (department.length >= 2) {
          setDepartmentError(false);
          setDepartmentError2("");
          if (!nameRe.test(department)) {
            setDepartmentError(true);
            setDepartmentError2("Please enter valid department");

          }
          else {
            setDepartmentError(false);
            setDepartmentError2("");

          }
        }
        else {
          setDepartmentError(true);
          setDepartmentError2("Please enter valid department");

        }
      }
      else {
        setDepartmentError(true);
        setDepartmentError2("Please enter department");


      }
    }
    else {
      if (sendDepartment) {
        setDepartmentError2("");
      }
      else {
        setDepartmentError2("Please select department");

      }
    }



    if (role) {

      if (role.length >= 2) {
        setRoleError(false);
        setRoleError2("");
        if (!nameRe.test(role)) {
          setRoleError(true);
          setRoleError2("Please enter valid role");

        }
        else {
          setRoleError(false);
          setRoleError2("");

        }
      }
      else {
        setRoleError(true);
        setRoleError2("Please enter valid role");

      }
    }
    else {
      setRoleError(true);
      setRoleError2("Please enter role");


    }



    if (nameError == false && selectedFileError == false && sequnceError == false && departmentError == false && roleError == false && role && fname && sequnce && selectedFile) {
      if (sendDepartment.value == "Others") {
        if (department) {
          MainFun(0);
        }
      }
      else {
        MainFun();
      }



    }
    else {
      debugger
    }
  }

  function MainFun(i) {
    let formData = new FormData();
    formData.append('image', selectedFile ? selectedFile : "");
    // formData.append('short_description', content);
    formData.append('role', role);
    formData.append('name', fname);
    formData.append('department', i == 0 ? department : sendDepartment.value);
    formData.append('sequence_No', sequnce);



    let data = propsValue ? propsValue.teammember_id ? propsValue.teammember_id : "" : ""


    if (data) {
      formData.append('teammember_id', data);
      dispatch(dispatchLoadingStatus(true));

      dispatch(ourTeamEdit_API(formData));

    }

    else {
      dispatch(dispatchLoadingStatus(true));

      dispatch(ourTeamAdd_API(formData));
    }
  }

  {/* Next relese */}
  // useEffect(() => {

  //   if (department.length > 0) {
  //     let formData = new FormData();

  //     formData.append('department', department);
  //     dispatch(ourTeamSequence_API(formData));

  //   }
  //   else {
  //     if (sendDepartment) {
  //       if (sendDepartment.value== "" || sendDepartment.value) {
  //         let formData = new FormData();
  //         formData.append('department', sendDepartment.value);

  //         dispatch(ourTeamSequence_API(formData));
  //       }
  //     }
  //   }
  // }, [department, sendDepartment])


  useEffect(() => {
    if (ourTeamSequenceReducer) {
      setSequnceList(ourTeamSequenceReducer.data);


    }
    else {
      setSequnceList([]);

    }

  }, [ourTeamSequenceReducer])
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />


        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.teammember_id ? "Our Team Edit" : "Our Team Add" : "Our Team Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/OurTeamList">Our Team List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.teammember_id ? "Our Team Edit" : "Our Team Add" : "Our Team Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">


                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Name:</label>
                              <input type="text" className="form-control" value={fname} onChange={(e) => handleFname(e)} />
                              <p className='errorr'>{nameError2}</p>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Department:</label>

                              <Select className='w-100'
                                options={dataList ? [{ label: 'Select', value: '' }].concat(dataList.map((dataItem) => ({
                                  value: dataItem.value,
                                  label: dataItem.label,
                                }))) : [{ label: "Select", value: "" }]}
                                placeholder="Select"
                                isClearable={false}
                                value={sendDepartment}
                                onChange={(e) => handleDepartment2(e)}
                              /> <br />
                              {sendDepartment && sendDepartment.value == "Others" || department ?
                                <input type="text" className="form-control" value={department} onChange={(e) => handleDepartment(e.target.value)} />
                                : ""}
                              <p className='errorr'>{departmentError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Role:</label>
                              <input type="text" className="form-control" value={role} onChange={(e) => handleRole(e)} />
                              <p className='errorr'>{roleError2}</p>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Sequence:</label>
                              <input type="text" className="form-control" value={sequnce} onChange={(e) => handleSequnce(e)} maxLength={2} />
                              {/* <Select className='w-100'
                                options={dataList ? [{ label: 'Select', value: '' }].concat(dataList.map((dataItem) => ({
                                  value: dataItem.value,
                                  label: dataItem.label,
                                }))) : [{ label: "Select", value: "" }]}
                                placeholder="Select"
                                isClearable={false}
                                value={sequnce}
                                onChange={(e) => handleSequnce(e)}
                              /> */}
                              <p className='errorr'>{sequnceError2}</p>
                            </div>



                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image:</label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">Add image:</label>
                                    </div>
                                  </div>

                                </form>
                                <p className='errorr'>{selectedFileError2}</p>


                                {selectedFile && (
                                  <div className="choosed-files" style={{ position: 'relative' }}>
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile : URL.createObjectURL(selectedFile)}
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()} style={{ zIndex: '99', position: 'absolute', right: '8px', top: '8px' }} />

                                  </div>
                                )}

                              </div>

                            </div>

                            {/* <div className="form-group col-sm-6">
                                <label className="form-label">Sequence :</label>
                                <div className='d-flex Sequence-main-div'>
                              { 
                                sequnceList && sequnceList.length > 0 ? sequnceList.map((i) =>

                                  <p> {i.sequence_No}</p>
                                ) : "No Sequence "
                              }
                              </div>
                            </div> */}

                          </div>
                          <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                          <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add